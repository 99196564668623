<template>
  <div class="container-fluid">
    <div class="row min-vh-100 flex-center no-gutters">
      <div class="col-lg-8 col-xxl-5 py-3"><img class="bg-auth-circle-shape" src="../../assets/img/illustrations/bg-shape.png" alt="" width="250"><img class="bg-auth-circle-shape-2" src="../../assets/img/illustrations/shape-1.png" alt="" width="150">
        <div class="card overflow-hidden z-index-1">
          <div class="card-body p-0">
            <div class="row no-gutters h-100">
              <div class="col-md-5 text-white text-center bg-card-gradient">
								<div class="position-relative p-4 pt-md-5 pb-md-7">
									<div 
										class="bg-holder bg-auth-card-shape" 
										style="background-image:url(/img/illustrations/half-circle.png);">
									</div>
									<!--/.bg-holder-->

									<div class="z-index-1 position-relative">
										<router-link 
                      style="text-decoration: none;"
											class="text-white mb-4 text-sans-serif font-weight-extra-bold fs-4 d-inline-block" 
											to="/">
											<small>get</small>PLAN
										</router-link>
										<p class="text-100">
											Надежный и практичный иструмент помогающий обрести 
                      финансовую <strong>стабильность</strong> и <strong>независимость</strong>!
										</p>
									</div>
								</div>
								<div class="mt-3 mb-4 mt-md-4 mb-md-5">
									<p class="mb-0 mt-4 mt-md-5 fs--1 font-weight-semi-bold text-300">
										Прочитать 
                    <a class="text-underline text-300" href="#!">условия</a> 
                    <br> и <a class="text-underline text-300" href="#!">политику конфеденциальности</a>
									</p>
								</div>
							</div>
              <div class="col-md-7 d-flex flex-center">
                <div class="p-4 p-md-5 flex-grow-1">
                  <h3>Сбросить пароль</h3>
                  <form class="mt-3">
                    <div class="form-group">
                      <label for="card-reset-password">Пароль</label>
                      <input class="form-control" type="password" id="card-reset-password" />
                    </div>
                    <div class="form-group">
                      <label for="card-reset-confirm-password">Подтвердите пароль</label>
                      <input class="form-control" type="password" id="card-reset-confirm-password" />
                    </div>
                    <button class="btn btn-primary btn-block mt-3" type="submit" name="submit">Обновить пароль</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>